import React, { useState, useEffect} from 'react'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Skeleton, { SkeletonTheme }  from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Backdrop from '../Backdrop/Backdrop';
import { Link, useParams } from 'react-router-dom';
import Footer from '../Home/Footer';
const MainOfAds = () => {
     let {id} = useParams() 
    const [data, setData] = useState(null)
    const [imgs, setimgs] = useState([])
    const [error, setError] = useState(null);
    const [date, setDate] = useState("");
    const [refresh, setRefresh] = useState(false);
    const [userPhone, setUserPhone] = useState(null)
    let fetchUrl = `https://api.divardi.li/?get=${id}/`
    const userToken = localStorage.getItem("token")
    useEffect(() => {

            const req = async () => {
                const token =  userToken ? ` ${userToken}` : '000';
                try {
                    // const headers = `"Authorization" : ${userToken}`

                    const headers = {
                      "Authorization" :  token
                    }
                const sendReq = await fetch(fetchUrl,{
                // const sendReq = await fetch("https://api.own3r.me/dil/",{
                method: "POST",
                // mode: "no-cors",
                headers: headers
            })
            const resData = await sendReq.json() 
               setData(resData)
               setimgs(resData[0]['images'].reverse())
               if (resData[0]["user"]) {
                setUserPhone(resData[0]["user"])
               }
               let nowDate = Date.now() / 1000
                let exactDate = Math.ceil((nowDate - resData[0].date) / 60)

                let unit = 'لحظاتی '
                if (exactDate > 4) {
                // exactDate = 0
                unit = 'دقیقه'
                }


                if (exactDate > 1440) {
                exactDate = Math.round(exactDate / 1440)
                unit = 'روز'
                }


                if (exactDate > 60) {
                exactDate = Math.round(exactDate / 60)
                unit = 'ساعت'
            }
            setDate((unit === 'لحظاتی ') ? (unit  + " پیش") : exactDate + " " + unit  + " پیش")
        } catch (error) {
            console.error(error)
            if (error.message === "Failed to fetch") {
                setError("خطای ارتباط با اینترنت")
            } else {
                setError(error.message)
            }
        }
        }
            req()
    }, [fetchUrl, refresh])
    if (data) {
        document.title = `${data[0].title + ' - دیوار دیل'}`
    }
    const [picUrl, setPicUrl] = useState(null)
    const showSinglePic = () => {
        setPicUrl(null)
    }


    const shareHandler = () => {
        if (navigator.share) {
            const title = document.querySelector("#title").innerText
            navigator.share({
                title: title,
                text: `آگهی ${title} را در دیوار دیل مشاهده کنید.`,
                url: window.location.href
            })
        }
    }

        return (
            <SkeletonTheme baseColor="#4c4c4c" highlightColor="#626262" inline="true" duration={"3"} height={30} borderRadius={"1rem"}>
                                {error ? <Backdrop>
                        <div className="h-[100vh] fixed left-0 right-0 bg-gray-700 overflow-hidden">
                         <div className="flex flex-col h-[50vh] items-center justify-center text-[#fff] text-[1.6rem] overflow-hidden">
                           <div className="flex items-center justify-center underline">
                            {error}
                            <svg width="60px" height="60px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g>
                            <path d="M17.8506 11.5442C17.0475 10.6829 16.0641 10.0096 14.9707 9.57227M20.7759 8.81625C19.5712 7.52437 18.0961 6.51439 16.4561 5.8584C14.816 5.20241 13.0514 4.91635 11.2881 5.02111M8.34277 14.5905C8.95571 13.9332 9.73448 13.4532 10.5971 13.2012C11.4598 12.9491 12.3745 12.9335 13.2449 13.1574M6.14941 11.5438C7.09778 10.5268 8.29486 9.77461 9.62259 9.36133M3.22363 8.81604C4.1215 7.85319 5.17169 7.04466 6.33211 6.42285M4.41406 4L18.5562 18.1421M12 19C11.4477 19 11 18.5523 11 18C11 17.4477 11.4477 17 12 17C12.5523 17 13 17.4477 13 18C13 18.5523 12.5523 19 12 19Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                            </svg>
                            </div>
                         <button onClick={() => 
                         {
                            setError(null)
                            setRefresh(!refresh)
                         }} className="btn flex items-center justify-center p-[10px] mt-[15px] text-[#fff] text-[1.6rem] bg-[#4985ca] rounded-[10px] ">تلاش مجدد</button>
                         </div>
                        </div>
                    </Backdrop> : ''}
            <div className="fixed bottom-0 left-0 p-[12px_16px] w-[100%] bg-[rgba(255,255,255,.98)] shadow-[0_-1px_2px_0_rgba(0,0,0,.12)] z-[55] pt-[12px]">
            {!error && <div className="flex justify-end">
            {userToken ?
                <>
          {data && data[0]["chat_disabled"] != 1 && <Link to={`/chat/${data && data[0].id}`} className="flex-1 inline-flex ml-[10px] box-border bg-[#a62626] items-center rounded-[5px] text-[#fff] cursor-pointer text-[1rem] font-[500] h-[2.5rem] justify-center leading-normal outline-none overflow-hidden px-[16px] relative min-w-[125px]"> <button>
            <span className="overflow-hidden text-ellipsis whitespace-nowrap pointer-events-none"> چت </span>
            </button>
             </Link>}
            <a href={`tel://${userPhone}`} target="_blank" rel="nofollow noreferrer noopener" className="flex-1 inline-flex box-border bg-[#a62626] items-center rounded-[5px] text-[#fff] cursor-pointer text-[1rem] font-[500] h-[2.5rem] justify-center leading-normal outline-none overflow-hidden px-[16px] relative min-w-[125px]">
            <span className="overflow-hidden text-ellipsis whitespace-nowrap pointer-events-none">  تماس </span>
            </a>
         </> :       
            <> <Link to={"/login"} className="flex-1 inline-flex ml-[10px] box-border bg-[#a62626] items-center rounded-[5px] text-[#fff] cursor-pointer text-[0.8rem] font-[500] h-[2.5rem] justify-center leading-normal outline-none overflow-hidden px-[16px] relative min-w-[125px]"> <button>
            <span className="overflow-hidden text-ellipsis whitespace-nowrap pointer-events-none"> برای نمایش اطلاعات تماس، وارد حساب کاربری خود شوید! </span>
            </button>
             </Link>

            </> 
        }
            </div>}
        
            </div>


        <div className="flex flex-col justify-between min-h-[97vh] pb-[4rem] container mx-auto w-[100%] px-[16px]">
   {picUrl && <Backdrop> 
        <div className='flex items-center justify-center h-[100%] overflow-y-scroll' onClick={showSinglePic}>
            <img src={picUrl} alt='' />
        </div>
     </Backdrop>}
        <div className="flex flex-wrap mx-[-8px]">
            <div className="flex-grow max-w-[100%] basis-0">
                <div>
                   <Carousel infiniteLoop="true" width='100%' dynamicHeight="ture" showStatus={false} onClickItem={(i,item) => setPicUrl(item.props.src)}>
                       {imgs && imgs.map((dt, index) => {
                          return  <img src={`https://static.divardi.li/images/${dt}`} alt='' key={index} />
                        })}
                

                    </Carousel>
                    {!data ? <div className='overflow-hidden text-center'><Skeleton  width="90vw" height="20em" style={{"overflow": "hidden"}} /> </div>  : ""}
                </div>
                <div className="flex flex-wrap">
                    <div className="order-1 flex-1">
                        <div className="m-0 text-[1.5rem] text-[rgba(0,0,0,.87)] font-[500] leading-[1.5] word-break hyphens-auto" id="title">
                          {data && data[0].title}
                        </div>
                        <div className='text-[1.1rem] text-[rgba(62,61,61,.95)] font-[500] leading-[2] m-[8px_0_16px]'>
                        {date && date}
                        </div>
                    </div>  
                </div>


                <div className="pt-[16px]">
                    <div className="flex nowrap text-[1.15rem] overflow-hidden whitespace-nowrap text-[rgba(0,0,0,.86)] font-[500] justify-between leading-[2] min-h-[48px] py-[8px]">
                            <div className="flex items-start min-w-[20%] ">
                                <p className="m-0 text-[rgba(0,0,0,.9)] leading-[2] overflow-hidden text-ellipsis"> قیمت </p>  
                            </div>
                            
                            <div className='flex items-start mr-[16px] min-w-[20%] max-w-[75%]'>
                                <p className="m-0 text-[rgba(0,0,0,.9)] leading-[2] overflow-hidden text-ellipsis">{!data && <Skeleton  width={130} /> }{data && data[0].price > 0 ? data[0].price.toLocaleString() + " تومان " :  ""} {data && data[0].price === 0 ? "توافقی" :  ""}   </p>  
                            </div>
                    </div>

                  <hr className='divider' />
                  <div className="flex nowrap text-[1.15rem] relative m-[10px_0] overflow-hidden whitespace-nowrap text-[rgba(0,0,0,.86)] font-[500] justify-between leading-[2] min-h-[90px]">
                            <div className="flex items-start min-w-[20%] "> 
                                <p className="m-0 text-[rgba(0,0,0,.9)] leading-[2] overflow-hidden text-ellipsis"> {data ? <><button onClick={() => shareHandler()} className="btn absolute left-0 right-0 w-[35%] m-[auto] flex items-center justify-center p-[5px] text-[#fff] text-[1rem] bg-[#4985ca] rounded-[10px] hover:bg-[#5a84b5]">به اشتراک گذاری</button> <a href={window.location.href} rel="noreferrer" target="_blank">  <button className="btn absolute mt-[45px] left-0 right-0 w-[35%] m-[auto] flex items-center justify-center p-[5px] text-[#fff] text-[1rem] bg-[#4985ca] rounded-[10px] hover:bg-[#5a84b5]">نمایش در سایت</button></a> </> :  <Skeleton  width={130} /> }  </p>  
                            </div>
                            
                    </div>
                  <hr className='divider' />



                    <div className='mt-[16px]'>
                        <div className="flex mt-[24px] items-start">
                            <div className="flex items-end">
                                <span className="m-0 text-[rgba(0,0,0,.87)] text-[1.125rem] font-[400] leading-[1.5]">توضیحات</span>
                                </div>
                        </div>
                        <div className="flex p-0 text-[1rem] min-h-[auto] relative text-[rgba(0,0,0,.56)] font-[400] justify-between leading-[2]">
                            <div className='flex items-start min-w-0 flex-1'>
                                <p id='description' className="leading-[2] font-[400] text-[1rem] text-[rgba(0,0,0,.87)] whitespace-pre-line word-break">
                                {data ? data[0].description :  <span className='overflow-hidden text-center'><Skeleton  width="60vw" count={3} style={{"overflow": "hidden"}}  /></span> }
                                </p>
                                {/* {document.querySelector("#description").innerHTML = data && data[0].description} */}

                            </div>

                        </div>
                    </div>

                </div>



                <div className="pt-[16px]">
                        <div className="flex flex-wrap items-start m-[-8px_0_0_-8px]">
                            <Link to={`/cat/${data && data[0].cat}`} className='bg-[rgba(0,0,0,.04)] inline-flex items-center cursor-pointer h-[2rem] p-[0_12px] text-[0.9rem] rounded-[7px] m-[8px_0_0_8px]'>
                            {data && data[0].cat_name}
                            </Link>
                        </div>
                </div>
            </div>
        </div>
        <hr className='divider mt-2' />
        <hr className='divider mt-2' />
    <Footer />
</div>
    </SkeletonTheme>
  )
}

export default MainOfAds