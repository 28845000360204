import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
const Header = () => {
  const [search, setSearch] = useState("")
  const navigate = useNavigate()

  const submitHandler = (e) => {
    if (search === "") {
      e.preventDefault();
      return;
    }
    return navigate(`/s/${search}`)
  }
  return (
    <header className="flex px-[16px] bg-[#fff] items-center shadow-[0_1px_2px_0_rgba(0,0,0,.12)] h-[64px] sticky top-0 w-[100%] z-[51]">
      <nav className="flex items-center flex-1">
        <form className=" flex items-center bg-[rgba(0,0,0,.04)] rounded-[4px] grow" onSubmit={submitHandler}>
        <Link to={"/"} className="flex w-[2.5rem] mr-[10px]"><svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M21.4498 10.275L11.9998 3.1875L2.5498 10.275L2.9998 11.625H3.7498V20.25H20.2498V11.625H20.9998L21.4498 10.275ZM5.2498 18.75V10.125L11.9998 5.0625L18.7498 10.125V18.75H14.9999V14.3333L14.2499 13.5833H9.74988L8.99988 14.3333V18.75H5.2498ZM10.4999 18.75H13.4999V15.0833H10.4999V18.75Z" fill="#080341"/>
</svg></Link>
            <div className="relative w-auto h-[2.5rem] flex-1">
                <input type="search" onChange={e => setSearch(e.target.value)} className="pr-[40px] pl-[16px] absolute h-[2.5rem] leading-[2.5rem] bg-transparent truncate border-[rgba(0,0,0,0%)] border-[1px] border-solid box-border rounded-[4px] text-[#000000de] shadow-none text-[1rem] outline-none w-[100%]" placeholder="جستجو در همه آگهی ها" autoComplete="off" id="search" />
                <div className="icon h-[2.5rem] leading-[2.5rem] absolute text-[rgba(0,0,0,.32)] px-[8px] right-0">
                <button type="submit"> <svg className="text-[1.5rem] h-[24px] w-[24px] inline-block" fill="#8b8b8b" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="50px" height="50px"><path d="M 21 3 C 11.621094 3 4 10.621094 4 20 C 4 29.378906 11.621094 37 21 37 C 24.710938 37 28.140625 35.804688 30.9375 33.78125 L 44.09375 46.90625 L 46.90625 44.09375 L 33.90625 31.0625 C 36.460938 28.085938 38 24.222656 38 20 C 38 10.621094 30.378906 3 21 3 Z M 21 5 C 29.296875 5 36 11.703125 36 20 C 36 28.296875 29.296875 35 21 35 C 12.703125 35 6 28.296875 6 20 C 6 11.703125 12.703125 5 21 5 Z"/></svg></button>
                </div>
            </div>
            <hr className="mx-[4px] inline-block h-[1.5rem] w-[2px] border-none box-border bg-[rgba(0,0,0,.12)]"/>
            <button className="bg-transparent border-none text-[rgba(0,0,0,.56)] cursor-pointer outline-none p-[0_8px_0_8px] text-[20px] font-[700]" type="button">
            دیل
            <svg className="inline-block mr-[5px]" fill="#525252" width="20px" height="20px" viewBox="-4 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">

    <g stroke="none">
        <g transform="translate(-104.000000, -411.000000)" fill="#000000">
            <path d="M116,426 C114.343,426 113,424.657 113,423 C113,421.343 114.343,420 116,420 C117.657,420 119,421.343 119,423 C119,424.657 117.657,426 116,426 L116,426 Z M116,418 C113.239,418 111,420.238 111,423 C111,425.762 113.239,428 116,428 C118.761,428 121,425.762 121,423 C121,420.238 118.761,418 116,418 L116,418 Z M116,440 C114.337,440.009 106,427.181 106,423 C106,417.478 110.477,413 116,413 C121.523,413 126,417.478 126,423 C126,427.125 117.637,440.009 116,440 L116,440 Z M116,411 C109.373,411 104,416.373 104,423 C104,428.018 114.005,443.011 116,443 C117.964,443.011 128,427.95 128,423 C128,416.373 122.627,411 116,411 L116,411 Z">

</path>
        </g>
    </g>
</svg>
            </button>
        </form>
      </nav>
    </header>
  );
};

export default Header;
