import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import Contents from "./Contents";
import Backdrop from "../Backdrop/Backdrop";

const Main = ({ adsID }) => {
  let { id } = useParams();
  let { query } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const location = useLocation();
  let user = localStorage.getItem("token")
  let fetchUrl = "https://api.divardi.li/";
  const myPosts = !id && !adsID && location.pathname.includes("my/posts")
  if (id) {
    fetchUrl = fetchUrl + "?cat=" + id;
  }
  if (adsID) {
    fetchUrl = fetchUrl + "?get=" + adsID;
  }
  if (myPosts) {
    fetchUrl = fetchUrl + "?data=" + user
  }
  if (query) {
    fetchUrl = fetchUrl + "?query=" + query
  }
  useEffect(() => {

      const req = async () => {

        try {
          const token =  user ? ` ${user}` : '000';


              const headers = {
                "Authorization" :  token
              }
        const sendReq = await fetch(fetchUrl, {
          // const sendReq = await fetch("https://api.own3r.me/dil/",{
          method: "POST",
          headers: headers
        });
        const resData = await sendReq.json();
        setData(resData);
    } catch (error) {
        if (error.message === "Failed to fetch") {
            setError("خطای ارتباط با اینترنت")
        } else {
            setError(error.message)
        }
        // setLoading(false)
        console.error(error.message === "Failed to fetch");
    }
}
    req();
  }, [fetchUrl, refresh]);
  return (
    <div className="main pb-[56px]">
      <div className="relative p-0 mx-a md:max-w-[100%]">
        {!adsID && (
          <header className="sticky z-10 top-[64px] bg-[#fff] shadow-[0_1px_2px_0_rgba(0,0,0,.12)] w-[100%] h-[48px]">
            <nav className="px-[16px] h-[3rem]">
              <div className="relative w-[100%] h-[100%]">
                <div className="flex items-center flex-row overflow-auto no-scrollbar h-[100%] w-[100%]">
                  <Link
                    to="/cat"
                    className="ml-[8px] text-[rgba(0,0,0,.56)] flex-shrink-0 pr-[8px] bg-transparent border-solid border-[1px] border-[rgba(0,0,0,.12)] rounded-[16px] items-center inline-flex text-[0.875rem] font-[600] h-[2rem] outline-none px-[12px]"
                  >
                    <svg
                      className="text-[1.5rem] ml-[4px]"
                      viewBox="0 0 600 600"
                      version="1.1"
                      width="14px"
                      height="14px"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#505050"
                      transform="matrix(-1, 0, 0, 1, 0, 0)"
                    >
                      <g strokeWidth="0"></g>
                      <g></g>
                      <g>
                        <defs></defs>
                        <g transform="matrix(0.95173205,0,0,0.95115787,13.901174,12.168794)">
                          <g transform="matrix(1.3807551,0,0,1.2700888,273.60014,263.99768)"></g>{" "}
                          <g transform="matrix(1.5092301,0,0,1.3955555,36.774048,-9.4503933)"></g>{" "}
                          <path
                            d="m 116.82051,533.90848 c 0,-35.77923 -30.001428,-65.78273 -65.71225,-65.78274 -35.710867,10e-6 -65.714445,30.00351 -65.714445,65.78274 0,35.7791 30.003578,65.78265 65.714445,65.78265 35.710822,0 65.71225,-30.00355 65.71225,-65.78265 z"
                            id="path1237"
                          ></path>
                          <path
                            d="m 116.82051,302.6125 c 0,-35.77918 -30.001428,-65.78266 -65.71225,-65.78267 -35.710867,1e-5 -65.714445,30.00349 -65.714445,65.78267 2.6e-5,35.77922 30.003597,65.78043 65.714445,65.78045 35.710806,-2e-5 65.71224,-30.00123 65.71225,-65.78045 z"
                            id="path1235"
                          ></path>
                          <path
                            d="m 116.82051,71.313209 c 0,-35.779199 -30.001428,-65.7804582 -65.71225,-65.7804662 -35.710867,8e-6 -65.714445,30.0012672 -65.714445,65.7804662 0,35.779271 30.003578,65.782741 65.714445,65.782751 35.710822,-1e-5 65.71225,-30.00348 65.71225,-65.782751 z"
                            id="path346"
                          ></path>
                          <path
                            d="m 211.58808,21.527961 a 47.572886,49.785578 0 0 0 -47.57256,49.787552 47.572886,49.785578 0 0 0 47.57256,49.785227 H 568.24859 A 47.572886,49.785578 0 0 0 615.82336,71.315513 47.572886,49.785578 0 0 0 568.24859,21.527961 Z"
                            id="path1011"
                          ></path>{" "}
                          <path
                            d="m 211.58808,252.82616 a 47.572886,49.785578 0 0 0 -47.57256,49.78524 47.572886,49.785578 0 0 0 47.57256,49.78523 h 356.66051 a 47.572886,49.785578 0 0 0 47.57477,-49.78523 47.572886,49.785578 0 0 0 -47.57477,-49.78524 z"
                            id="path1011-7"
                          ></path>
                          <path d="m 211.58808,484.1232 a 47.572886,49.785578 0 0 0 -47.57256,49.78523 47.572886,49.785578 0 0 0 47.57256,49.78524 h 356.66051 a 47.572886,49.785578 0 0 0 47.57477,-49.78524 47.572886,49.785578 0 0 0 -47.57477,-49.78523 z"></path>
                        </g>
                      </g>
                    </svg>
                    دسته‌ها
                  </Link>
                  <Link
                    to="/cat/car"
                    className="ml-[8px] text-[rgba(0,0,0,.56)] flex-shrink-0 bg-transparent border-solid border-[1px] border-[rgba(0,0,0,.12)] rounded-[16px] items-center inline-flex text-[0.875rem] font-[600] h-[2rem] outline-none px-[12px]"
                  >
                    خودرو سواری
                  </Link>

                  <Link
                    to="/cat/estate"
                    className="ml-[8px] text-[rgba(0,0,0,.56)] flex-shrink-0 bg-transparent border-solid border-[1px] border-[rgba(0,0,0,.12)] rounded-[16px] items-center inline-flex text-[0.875rem] font-[600] h-[2rem] outline-none px-[12px]"
                  >
                    املاک
                  </Link>

                  <Link
                    to="/cat/digital"
                    className="ml-[8px] text-[rgba(0,0,0,.56)] flex-shrink-0 bg-transparent border-solid border-[1px] border-[rgba(0,0,0,.12)] rounded-[16px] items-center inline-flex text-[0.875rem] font-[600] h-[2rem] outline-none px-[12px]"
                  >
                    کالای دیجیتال
                  </Link>

                  <Link
                    to="/cat/home"
                    className="ml-[8px] text-[rgba(0,0,0,.56)] flex-shrink-0 bg-transparent border-solid border-[1px] border-[rgba(0,0,0,.12)] rounded-[16px] items-center inline-flex text-[0.875rem] font-[600] h-[2rem] outline-none px-[12px]"
                  >
                    لوازم خانگی
                  </Link>

                  <Link
                    to="/cat/food"
                    className="ml-[8px] text-[rgba(0,0,0,.56)] flex-shrink-0 bg-transparent border-solid border-[1px] border-[rgba(0,0,0,.12)] rounded-[16px] items-center inline-flex text-[0.875rem] font-[600] h-[2rem] outline-none px-[12px]"
                  >
                    خوردنی و آشامیدنی
                  </Link>

                  <Link
                    to="/cat/services"
                    className="ml-[8px] text-[rgba(0,0,0,.56)] flex-shrink-0 bg-transparent border-solid border-[1px] border-[rgba(0,0,0,.12)] rounded-[16px] items-center inline-flex text-[0.875rem] font-[600] h-[2rem] outline-none px-[12px]"
                  >
                    خدمات
                  </Link>

                  <Link
                    to="/cat/other"
                    className="ml-[8px] text-[rgba(0,0,0,.56)] flex-shrink-0 bg-transparent border-solid border-[1px] border-[rgba(0,0,0,.12)] rounded-[16px] items-center inline-flex text-[0.875rem] font-[600] h-[2rem] outline-none px-[12px]"
                  >
                    متفرقه
                  </Link>
                </div>
              </div>
            </nav>
          </header>
        )}
        <main className="w-[100%] pt-[24px] m-0 relative block">
          {!adsID && (
            <h1 className="p-[0_16px] block text-[rgba(0,0,0,.56)] text-[0.95rem] font-[400] leading-[1.5rem] m-0">
              دیوار دیل:‌ انواع آگهی‌ها و خدمات در روستای دیل
            </h1>
          )}

          <div>
            <div className="p-[8px] flex">
              <div className="static h-[100%] w-[100%]">
                <div className="py-0 min-h-[100px] flex flex-wrap will-change-transform transform-[translateZ(0)]">
                <div className={'h-[184px] sm:max-w-[50%] max-w-[100%] relative w-[100%] p-[8px]'}>
                    <a href="https://static.divardi.li/divar-dil.apk" target="_blank" rel="noreferrer" download>
                            <article className='mb-[16px] flex flex-col rounded-[5px] h-[168px] p-[16px] relative bg-[#fff] border-[2px] border-solid border-yellow-400'>
                                <div className='flex'>
                                    <div className='flex flex-col flex-grow min-w-0 relative'>
                                        <h2 className='font-[500] text-[1rem] text-[rgba(0,0,0,.87)] h-[56px] leading-[28px] mb-auto mt-0 overflow-hidden break-words'> اپلیکیشن اندروید دیوار دیل </h2>
                                        <div className="description">
                          برای دانلود کلیک کنید.
                                        </div>
                                        <div className="flex items-center leading-[24px] break-words whitespace-nowrap">
                                            <span className='text-[rgba(0,0,0,.56)] text-[0.75rem] overflow-hidden text-ellipsis whitespace-nowrap'> هم اکنون </span>
                                        </div>
                                    </div>
                                    <div className='text-[rgba(0,0,0,.56)] flex flex-col justify-end'>
                                    {myPosts ? <img src='/remove.png' className='w-[25px] h-[25px]' alt='حذف این آگهی؟' /> : <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12 3C7.85113 3 4 5.73396 4 10C4 11.5704 4.38842 12.7289 5.08252 13.6554C5.79003 14.5998 6.87746 15.3863 8.41627 16.0908L9.2326 16.4645L8.94868 17.3162C8.54129 18.5384 7.84997 19.6611 7.15156 20.5844C9.56467 19.8263 12.7167 18.6537 14.9453 17.1679C17.1551 15.6948 18.3969 14.5353 19.0991 13.455C19.7758 12.4139 20 11.371 20 10C20 5.73396 16.1489 3 12 3ZM2 10C2 4.26604 7.14887 1 12 1C16.8511 1 22 4.26604 22 10C22 11.629 21.7242 13.0861 20.7759 14.545C19.8531 15.9647 18.3449 17.3052 16.0547 18.8321C13.0781 20.8164 8.76589 22.2232 6.29772 22.9281C5.48665 23.1597 4.84055 22.6838 4.56243 22.1881C4.28848 21.6998 4.22087 20.9454 4.74413 20.3614C5.44439 19.5798 6.21203 18.5732 6.72616 17.4871C5.40034 16.7841 4.29326 15.9376 3.48189 14.8545C2.48785 13.5277 2 11.9296 2 10Z" fill="#0F0F0F"/>
                                    </svg>}
                                    </div>
                                    <div className='imgInSmallDevices bg-[#f5f5f5] rounded-[4px] flex-[0_0_136px] h-[136px] mr-[5px] overflow-hidden w-[136px]'>
                                            <picture className='pb-[100%] rounded-[4px] block overflow-hidden relative w-[100%]'>
                                                <img className='object-cover block h-[100%] left-0 top-0 absolute w-[100%]' src='https://static.divardi.li/icon.png' alt='' />
                                                
                                            </picture>
                                    </div>
                                </div>
                            </article>
                        </a>
                        </div>
                  {!data && <Contents type="dummy" />}
                  {!data && <Contents type="dummy" />}
                  {!data && <Contents type="dummy" />}
                  {!data && <Contents type="dummy" />}
                    {error ? <Backdrop>
                        <div className="h-[100vh] fixed left-0 right-0 bg-gray-700 overflow-hidden">
                         <div className="flex flex-col h-[50vh] items-center justify-center text-[#fff] text-[1.6rem] overflow-hidden">
                           <div className="flex items-center justify-center underline">
                            {error}
                            <svg width="60px" height="60px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g>
                            <path d="M17.8506 11.5442C17.0475 10.6829 16.0641 10.0096 14.9707 9.57227M20.7759 8.81625C19.5712 7.52437 18.0961 6.51439 16.4561 5.8584C14.816 5.20241 13.0514 4.91635 11.2881 5.02111M8.34277 14.5905C8.95571 13.9332 9.73448 13.4532 10.5971 13.2012C11.4598 12.9491 12.3745 12.9335 13.2449 13.1574M6.14941 11.5438C7.09778 10.5268 8.29486 9.77461 9.62259 9.36133M3.22363 8.81604C4.1215 7.85319 5.17169 7.04466 6.33211 6.42285M4.41406 4L18.5562 18.1421M12 19C11.4477 19 11 18.5523 11 18C11 17.4477 11.4477 17 12 17C12.5523 17 13 17.4477 13 18C13 18.5523 12.5523 19 12 19Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                            </svg>
                            </div>
                         <button onClick={() => 
                         {
                            setError(null)
                            setRefresh(!refresh)
                         }} className="btn flex items-center justify-center p-[10px] mt-[15px] text-[#fff] text-[1.6rem] bg-[#4985ca] rounded-[10px] ">تلاش مجدد</button>
                         </div>
                        </div>
                    </Backdrop> : ''}
                  {data?.map((ads, index) => {
                    return (
                      <Contents
                        ad_id={ads.id || null}
                        name={ads.title}
                        price={ads.price}
                        date={ads.date}
                        images={ads.images || "no-pic.svg"}
                        key={index}
                        myPosts={myPosts}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Main;
