import React from "react";
import { useNavigate } from "react-router-dom";

const HeaderOfAds = ({showShare, nameInChat}) => {
    const navigate = useNavigate()
    const goBackHandler = () => {
        return navigate(-1);
    }


    const copyLink = async () => {
      try {
        const url = window.location.href;
        await navigator.clipboard.writeText(url);
       document.querySelector("#copy").style =  "background-color: #2c8d1a9c";
        setTimeout(() => {
            document.querySelector("#copy").style = "";
        }, 4000);
      } catch (err) {
        console.error(err)
      }
    }

  return (
    <header className="flex px-[16px] bg-[#fff] items-center shadow-[0_1px_2px_0_rgba(0,0,0,.12)] h-[64px] sticky top-0 w-[100%] z-[51]">
      <nav className="flex items-center flex-1">
      <button className='rounded-[50%] h-[2.5rem] w-[2.5rem] min-w-[auto] p-0 bg-transparent text-[rgba(0,0,0,.56)] inline-flex items-center border-solid border-[1px] border-transparent box-border cursor-pointer text-[1.3rem] font-[800] justify-center leading-normal outline-none overflow-hidden relative hover:bg-[rgba(0,0,0,.04)]' onClick={goBackHandler}>
      <svg fill="#000000" height="20px" width="20px" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.036 512.036"><g><g><path d="M508.916,248.351L295.583,35.018c-4.267-4.053-10.987-3.947-15.04,0.213c-3.947,4.16-3.947,10.667,0,14.827l195.093,195.2 H10.996c-5.333,0-10.133,3.84-10.88,9.067c-0.96,6.613,4.16,12.267,10.56,12.267h464.96L280.543,461.685 c-4.267,4.053-4.373,10.88-0.213,15.04c4.053,4.267,10.88,4.373,15.04,0.213c0.107-0.107,0.213-0.213,0.213-0.213l213.333-213.333 C513.076,259.338,513.076,252.511,508.916,248.351z"/></g></g></svg>
      </button>
      {nameInChat && <div className="mr-[1rem]">{nameInChat}</div>}
      </nav>

      {showShare !== false && <nav className="flex items-center mr-[8px] flex-row-reverse">
      <button onClick={() => copyLink()} id="copy" className='rounded-[50%] h-[2.5rem] w-[2.5rem] min-w-[auto] p-0 bg-transparent text-[rgba(0,0,0,.56)] inline-flex items-center border-solid border-[1px] border-transparent box-border cursor-pointer text-[1.3rem] font-[800] justify-center leading-normal outline-none overflow-hidden relative hover:bg-[rgba(0,0,0,.04)]'>
      <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0_15_72)"><rect width="24" height="24" fill="none"/><circle cx="7" cy="12" r="2" stroke="#000000" strokeLinejoin="round"/><circle cx="17" cy="6" r="2" stroke="#000000" strokeLinejoin="round"/><path d="M15 7L8.5 11" stroke="#000000"/><circle cx="17" cy="18" r="2" stroke="#000000" strokeLinejoin="round"/><path d="M8.5 13.5L15 17" stroke="#000000"/></g><defs><clipPath><rect width="24" height="24" fill="white"/></clipPath></defs></svg>
      </button>
      </nav>}
    </header>
  );
};

export default HeaderOfAds;
