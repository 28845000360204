import React, { useEffect, useState } from "react";
import HeaderOfAds from "../../Header/HeaderOfAds";
import { useParams, useNavigate, Link, useSearchParams} from "react-router-dom";
import Backdrop from "../../Backdrop/Backdrop";
const Chat = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [chatId, setChatId] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate()
  let { id } = useParams();
  let chatIdQuery = searchParams.get('id')
  const token = localStorage.getItem("token") || null;
  const lsData = localStorage.getItem("data") || null;
  const getDate = (date) => {
    let correctDate;
    let nowDate = Date.now() / 1000;
    let exactDate = Math.ceil((nowDate - date) / 60);
    let unit = "لحظاتی ";
    if (exactDate > 4) {
      // exactDate = 0
      unit = "دقیقه";
    }

    if (exactDate > 1440) {
      exactDate = Math.round(exactDate / 1440);
      unit = "روز";
    }

    if (exactDate > 60) {
      exactDate = Math.round(exactDate / 60);
      unit = "ساعت";
    }

    correctDate =
      unit === "لحظاتی " ? unit + " پیش" : exactDate + " " + unit + " پیش";
    return correctDate;
  };
  useEffect(() => {
    const sendReq = async () => {
      try {
        let fetchUrl = `https://api.divardi.li/chat.php`
        // if (id) {
        //   fetchUrl = fetchUrl + `?get=${id}`
        // }
        
        if (chatIdQuery) {
          fetchUrl = fetchUrl + `?get=${chatIdQuery}`
        }else if (id === "postchi") {
          fetchUrl = fetchUrl + `?postchi=`
        }else if (!chatIdQuery) {
          fetchUrl = fetchUrl + `?get=`
        }
        const req = await fetch(fetchUrl,{
            method: "POST",
            body: JSON.stringify({token: token || null,
              id:id
            }),
            headers: {
              Authorization: token,
            },
          }
        );
        const response = await req.json();
        if (response) {
          setChatId(response[0].chatid)
        }
        setData(response);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setError(error.message);
      }
    };
    sendReq();
  }, [refresh]);



  const submitHandler = async () => {
    setIsLoading(true);
    try {
        const req = await fetch(`https://api.divardi.li/chat.php?sendMessage=`,{
            method: "POST",
            body: JSON.stringify({
              data: lsData || null,
              chatid: chatId,
              id: id,
              message: message,
              token: token
            }),
            headers: {
              Authorization: token,
            },
          }
        );
        const response = await req.json();
        setMessage("");
        if (response[0]["error"]) {
          setError(response[0]["error"])
         }
        if (response[0]["chatid"] > 0 && !chatIdQuery) {
          searchParams.set('id', response[0]["chatid"]);
          setSearchParams(searchParams)
        }
        setRefresh(!refresh)
        // setData(data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setError(error.message);
      }
  }
  return (
    <>
      {isLoading ? (
        <>
          <div className="flex fixed w-[100%] h-[100%] justify-center items-center">
            <img className="max-w-[200px]" src="/loading.svg" alt="" />
          </div>
        </>
      ) : (
        <>
        {error ? <Backdrop>
                        <div className="h-[100vh] fixed left-0 right-0 bg-gray-700 overflow-hidden">
                         <div className="flex flex-col h-[50vh] items-center justify-center text-[#fff] sm:text-[1.6rem] text-[1.2rem] overflow-hidden">
                           <div className="flex flex-col items-center justify-center underline">
                            {error}
                            <svg width="60px" height="60px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g>
                            <path d="M17.8506 11.5442C17.0475 10.6829 16.0641 10.0096 14.9707 9.57227M20.7759 8.81625C19.5712 7.52437 18.0961 6.51439 16.4561 5.8584C14.816 5.20241 13.0514 4.91635 11.2881 5.02111M8.34277 14.5905C8.95571 13.9332 9.73448 13.4532 10.5971 13.2012C11.4598 12.9491 12.3745 12.9335 13.2449 13.1574M6.14941 11.5438C7.09778 10.5268 8.29486 9.77461 9.62259 9.36133M3.22363 8.81604C4.1215 7.85319 5.17169 7.04466 6.33211 6.42285M4.41406 4L18.5562 18.1421M12 19C11.4477 19 11 18.5523 11 18C11 17.4477 11.4477 17 12 17C12.5523 17 13 17.4477 13 18C13 18.5523 12.5523 19 12 19Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                            </svg>
                            </div>
                         <button onClick={() => 
                         {
                            setError(null)
                            return navigate("/")
                         }} className="btn flex items-center justify-center md:p-[10px] p-[5px] mt-[15px] text-[#fff] text-[1.6rem] bg-[#4985ca] rounded-[10px] ">رفتن به صفحه اصلی </button>
                         </div>
                        </div>
                    </Backdrop> : ''}
          {data[0] && <div className="wrapper flex justify-between flex-col">
          <HeaderOfAds showShare={false} nameInChat={data[0]["receiver"] === token ? data[0]["sender_name"] : data[0]["receiver_name"]} />
          <Link
            to={"/v/" + data[0]["adid"]}
            className="shadow-[inset_0_-1px_0_rgba(0,0,0,.12)] items-center flex p-[8px_16px_8px_8px] bg-[#fafafa]"
          >
            <div className="w-[40px]">
              <picture className="h-[40px] rounded-[5px] block overflow-hidden relative w-[100%]">
                <img
                  className="block w-[100%] h-[100%] left-0 top-0 object-cover absolute"
                  src={
                    data[0]["images"]
                      ? `https://static.divardi.li/images/` +
                        data[0]["images"]
                      : "https://static.divardi.li/no-pic.svg"
                  }
                  alt=""
                />
              </picture>
            </div>
            <span className="leading-[2] text-[1rem] font-[500] text-[rgba(0,0,0,.87)] flex-1 m-[0_8px] text-right">
              {data[0]["title"]}
            </span>
          </Link>
          <div className="flex flex-1 relative w-[100%] h-[100%] overflow-auto will-change-scroll">
            <div className="flex flex-col justify-between min-w-[100%] min-h-[100%]">
              <div className="mb-[60px]">
                <div className="p-[0_8px]">
                  <div className="flex justify-center pb-[16px]">
                    <div className="p-[0_8px] text-[0.75rem] min-h-[24px] border-solid border-[1px] border-transparent bg-[rgba(0,0,0,.04)] items-center rounded-[3px] text-[rgba(0,0,0,.56)] inline-flex font-[500] h-auto overflow-hidden leading-[2]">
                      <span className="m-0 overflow-hidden text-ellipsis whitespace-nowrap flex-1">
                        {data[0]["content"] === null ? "هم اکنون": getDate(data[0]["content"][0]["date"])}
                        {/* {console.log(data[0]["content"][0]["date"])} */}
                      </span>
                    </div>
                  </div>
                </div>
                {data[0].content?.map((item, i) => {
                  return <React.Fragment key={i}>
                <div className="p-[0_8px]">
                  <div>
                    <div className={`flex pb-[16px] ${token === item.sender ? 'justify-start' : 'justify-end'}`}>
                      <div className={`text-[0.935rem] font-[500] leading-[2] p-[8px_12px_4px] bg-[#f5f5f5] text-[rgba(0,0,0,.87)] max-w-[70%] whitespace-pre-line word-break ${token === item.sender ? ' rounded-[16px_16px_0px_16px]' : ' rounded-[16px_16px_16px_2px]'}`}>
                        <span>
                          {/* insert message's text here */}
                          
                          {item.text}
                        </span>
                        <div className="flex items-center mt-[2px]">
                          <div className="flex items-center text-[rgba(0,0,0,.32)] font-[500] leading-[3] text-[0.8rem]">
                            {getDate(item.date)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                  </React.Fragment>
                })}


              </div>
                <div className="z-10 min-w-[100%] fixed bottom-0">
                  <div className="shadow-[0_-1px_2px_rgba(0,0,0,.12)] items-center bg-[#fff] flex p-[8px_0_8px_8px] relative">
                    <input type="text" onChange={(e) => setMessage(e.target.value)} value={message} placeholder="متنی بنویسید" className="py-[10px] text-[1rem] border-none rounded-[5px] text-[rgba(0,0,0,.87)] flex-1 m-[4px_24px_4px_0] outline-none p-0 overflow-visible" />
                    <button onClick={submitHandler} className="bg-[#a62626] mr-[8px] justify-center rounded-[50%] h-[3em] min-w-[auto] p-0 w-[3em] items-center border-solid border-transparent border-[1px] box-border text-[#fff] cursor-pointer inline-flex text-[1rem] font-[500] outline-none overflow-hidden relative">
                      <span>
                        <svg height="30px" width="30px" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44.224 44.224">
                          <g>
                            <path fill="#fff" d="M22.112,44.224c1.657,0,3-1.343,3-3v-25.66c0-2.761,1.541-3.376,3.443-1.374l7.045,7.419
		c0.616,0.649,1.445,0.976,2.274,0.976c0.774,0,1.553-0.286,2.158-0.861c1.258-1.193,1.308-3.177,0.115-4.434L24.653,0.977
		C24.068,0.359,23.256,0.007,22.406,0c-0.851,0.002-1.665,0.33-2.261,0.938L4.114,17.254c-1.215,1.234-1.197,3.22,0.036,4.433
		c1.235,1.214,3.221,1.195,4.434-0.039l7.024-7.148c1.935-1.97,3.504-1.328,3.504,1.433v25.291
		C19.112,42.881,20.455,44.224,22.112,44.224z" />
                          </g>
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>              
            </div>
          </div>
          </div>}
        </>
      )}
    </>
  );
};

export default Chat;
