import React, { useEffect, useState } from "react";
import HeaderOfAds from "../../Header/HeaderOfAds";
const GetPostchi = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const token = localStorage.getItem("token") || null;
  const getDate = (date) => {
    let correctDate;
    let nowDate = Date.now() / 1000;
    let exactDate = Math.ceil((nowDate - date) / 60);
    let unit = "لحظاتی ";
    if (exactDate > 4) {
      // exactDate = 0
      unit = "دقیقه";
    }

    if (exactDate > 1440) {
      exactDate = Math.round(exactDate / 1440);
      unit = "روز";
    }

    if (exactDate > 60) {
      exactDate = Math.round(exactDate / 60);
      unit = "ساعت";
    }

    correctDate =
      unit === "لحظاتی " ? unit + " پیش" : exactDate + " " + unit + " پیش";
    return correctDate;
  };
  useEffect(() => {
    const sendReq = async () => {
      try {
        let fetchUrl = `https://api.divardi.li/chat.php?postchi=`
        // if (id) {
        //   fetchUrl = fetchUrl + `?get=${id}`
        // }
        
        const req = await fetch(fetchUrl,{
            method: "POST",
            body: JSON.stringify({token: token || null,
            }),
            headers: {
              Authorization: token,
            },
          }
        );
        const response = await req.json();
        if (response) {
        }
        setData(response);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setError(error.message);
      }
    };
    sendReq();
  }, []);


  return (
    <>
      {isLoading ? (
        <>
          <div className="flex fixed w-[100%] h-[100%] justify-center items-center">
            <img className="max-w-[200px]" src="/loading.svg" alt="" />
          </div>
        </>
      ) : (
        <>
          {error && error}
          {data[0] && <div className="wrapper flex justify-between flex-col">
          <HeaderOfAds showShare={false} nameInChat={"پستچی دیوار دیل"} />
          <div
            className="shadow-[inset_0_-1px_0_rgba(0,0,0,.12)] items-center flex p-[8px_16px_8px_8px] bg-[#fafafa]"
          >
            <div className="w-[40px]">
              <picture className="h-[40px] rounded-[5px] block overflow-hidden relative w-[100%]">
                <img
                  className="block w-[100%] h-[100%] left-0 top-0 object-cover absolute"
                  src={"/divar.svg"}
                  alt=""
                />
              </picture>
            </div>

          </div>
          <div className="flex flex-1 relative w-[100%] h-[100%] overflow-auto will-change-scroll">
            <div className="flex flex-col justify-between min-w-[100%] min-h-[100%]">
              <div className="mb-[60px]">
                <div className="p-[0_8px]">
                  <div className="flex justify-center pb-[16px]">
                  </div>
                </div>
                {data?.map((item, i) => {
                  return <React.Fragment key={i}>
                <div className="p-[0_8px]">
                  <div>
                    <div className={`flex pb-[16px] justify-end`}>
                      <div className={`text-[0.935rem] font-[500] leading-[2] p-[6px_14px_2px] bg-[#f5f5f5] text-[rgba(0,0,0,.87)] max-w-[70%] whitespace-pre-line word-break ${token === item.sender ? ' rounded-[16px_16px_0px_16px]' : ' rounded-[16px_16px_16px_2px]'}`}>
                        <span>
                          {/* insert message's text here */}
                          
                          {item.post}
                        </span>
                        <p className="text-[0.9rem] w-[15rem] text-[orange]">{item.message && 'پیام ادمین:' + item.message}</p>
                        <div className="flex items-center mt-[1px]">
                          <div className="flex items-center text-[rgba(0,0,0,.32)] font-[500] leading-[3] text-[0.8rem]">
                            {getDate(item.date)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                  </React.Fragment>
                })}


              </div>
           
            </div>
          </div>
          </div>}
        </>
      )}
    </>
  );
};

export default GetPostchi;
