import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import Skeleton, { SkeletonTheme }  from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Backdrop from '../Backdrop/Backdrop'
import {useNavigate, useResolvedPath } from 'react-router-dom'
const Contents = ({name, price, date, images, ad_id, type, myPosts}) => {
    let match = useResolvedPath("").pathname
    let isInMyPosts = match.includes("/my/posts")
    const [response, setResponse] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const navigate = useNavigate()
    let correctDate;
    if (date) {
        let nowDate = Date.now() / 1000
        let exactDate = Math.ceil((nowDate - date) / 60)
        let unit = 'لحظاتی '
        if (exactDate > 4) {
        // exactDate = 0
        unit = 'دقیقه'
        }


        if (exactDate > 1440) {
        exactDate = Math.round(exactDate / 1440)
        unit = 'روز'
        }


        if (exactDate > 60) {
        exactDate = Math.round(exactDate / 60)
        unit = 'ساعت'
    }
    correctDate = (unit === 'لحظاتی ') ? (unit  + " پیش") : exactDate + " " + unit  + " پیش"
    }
    const removeHandler = (e) =>{
        e.preventDefault()
        const idToRemove =  e.target.parentElement.parentElement.getAttribute("adid")
        let con =  window.confirm("آیا از حذف این آگهی اطمینان دارید؟")
        if (con) {
            let fetchUrl = `https://api.divardi.li/?remove=${idToRemove}`
            const userToken = localStorage.getItem("token")
            const phone = localStorage.getItem("phone")
            const req = async () => {
                const token =  userToken ? ` ${userToken}` : '000';
                try {
                    // const headers = `"Authorization" : ${userToken}`

                    const headers = {
                      "Authorization" :  token
                    }

                const sendReq = await fetch(fetchUrl,{
                // const sendReq = await fetch("https://api.own3r.me/dil/",{
                method: "POST",
                // mode: "no-cors",
                headers: headers,
                body: JSON.stringify({
                    token: userToken,
                    phone: phone
                })
            })
            const resData = await sendReq.json() 
            if (resData.removed) {
                setResponse("آگهی پاک شد!")
            }
        } catch (error) {
            console.error(error)
            if (error.message === "Failed to fetch") {
                setResponse("خطای ارتباط با اینترنت")
            } else {
                setResponse(error.message)
            }
        }
        }
        req()
        }
    }
  return (
    <SkeletonTheme baseColor="#4c4c4c" highlightColor="#626262" inline="true" duration={"3"} height={30} borderRadius={"1rem"}>
                                {response ? <Backdrop>
                        <div className="h-[100vh] fixed left-0 right-0 bg-gray-700 overflow-hidden">
                         <div className="flex flex-col h-[50vh] items-center justify-center text-[#fff] text-[1.6rem] overflow-hidden">
                           <div className="flex items-center justify-center underline">
                            {response}

                            </div>
                         <button onClick={() => 
                         {
                            setResponse(null)
                            setRefresh(!refresh)
                            return navigate("/");
                         }} className="btn flex items-center justify-center p-[10px] mt-[15px] text-[#fff] text-[1.6rem] bg-[#4985ca] rounded-[10px] ">رفتن به صفحه اصلی </button>
                         </div>
                        </div>
                    </Backdrop> : ''}<div className={type === "dummy" ? 'h-[184px] sm:max-w-[50%] max-w-[100%] relative w-[100%] p-[8px] dummy' : 'h-[184px] sm:max-w-[50%] max-w-[100%] relative w-[100%] p-[8px]'}>
                        <Link to={`/v/${ad_id}`}>
                            <article className='mb-[16px] flex flex-col rounded-[5px] h-[168px] p-[16px] relative bg-[#fff] border-solid border-[1px] border-[#80808069]'>
                                <div className='flex' adid={ad_id}>
                                    <div className='flex flex-col flex-grow min-w-0 relative'>
                                        <h2 className='font-[500] text-[1rem] text-[rgba(0,0,0,.87)] h-[56px] leading-[28px] mb-auto mt-0 overflow-hidden break-words'> {name || <Skeleton  width={160} />} </h2>
                                        <div className="description">
                                        {isNaN(price) && <Skeleton  width={90} />}
                                            {(price > 0) ? price.toLocaleString() : ""} 
                                            {price === 0 ? "توافقی" : ""} 
                                             {price ? ' تومان' : "" }
                                        </div>
                                        <div className="flex items-center leading-[24px] break-words whitespace-nowrap">
                                            <span className='text-[rgba(0,0,0,.56)] text-[0.75rem] overflow-hidden text-ellipsis whitespace-nowrap'> {correctDate || <Skeleton  width={90} />}</span>
                                        </div>
                                    </div>
                                    <div onClick={isInMyPosts ? removeHandler : undefined} className='text-[rgba(0,0,0,.56)] flex flex-col justify-end'>
                                    {myPosts ? <img src='/remove.png' className='w-[25px] h-[25px]' alt='حذف این آگهی؟' /> : <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12 3C7.85113 3 4 5.73396 4 10C4 11.5704 4.38842 12.7289 5.08252 13.6554C5.79003 14.5998 6.87746 15.3863 8.41627 16.0908L9.2326 16.4645L8.94868 17.3162C8.54129 18.5384 7.84997 19.6611 7.15156 20.5844C9.56467 19.8263 12.7167 18.6537 14.9453 17.1679C17.1551 15.6948 18.3969 14.5353 19.0991 13.455C19.7758 12.4139 20 11.371 20 10C20 5.73396 16.1489 3 12 3ZM2 10C2 4.26604 7.14887 1 12 1C16.8511 1 22 4.26604 22 10C22 11.629 21.7242 13.0861 20.7759 14.545C19.8531 15.9647 18.3449 17.3052 16.0547 18.8321C13.0781 20.8164 8.76589 22.2232 6.29772 22.9281C5.48665 23.1597 4.84055 22.6838 4.56243 22.1881C4.28848 21.6998 4.22087 20.9454 4.74413 20.3614C5.44439 19.5798 6.21203 18.5732 6.72616 17.4871C5.40034 16.7841 4.29326 15.9376 3.48189 14.8545C2.48785 13.5277 2 11.9296 2 10Z" fill="#0F0F0F"/>
                                    </svg>}
                                    </div>
                                    <div className='imgInSmallDevices bg-[#f5f5f5] rounded-[4px] flex-[0_0_136px] h-[136px] mr-[5px] overflow-hidden w-[136px]'>
                                            <picture className='pb-[100%] rounded-[4px] block overflow-hidden relative w-[100%]'>
                                                <img className='object-cover block h-[100%] left-0 top-0 absolute w-[100%]' src={(images !== undefined && images.length > 0) ? `https://static.divardi.li/images/${images[0]}` : 'https://static.divardi.li/no-pic.svg' }  alt={name} />
                                                 {images === undefined && <Skeleton height={130} />}
                                            </picture>
                                    </div>
                                </div>
                            </article>
                        </Link>
</div>
</SkeletonTheme>
  )
}

export default Contents