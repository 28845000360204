import React from 'react'
import Header from '../../Header/Header'
import Nav from '../../Nav/Nav'
import { Link, useNavigate } from 'react-router-dom'
import Footer from "../Footer"
const My = () => {
    const navigate = useNavigate()

    let userExist = false
    if (localStorage.getItem("token") && localStorage.getItem("phone") && document.cookie !== "") {
        userExist = true
    }
  return (
    <>
    <Header/>
    <div className='flex flex-col min-h-[80vh] pb-[82px]'>
    <div className="flex flex-1 flex-col justify-between p-[16px_16px_0]">
            {!userExist && <div>
            <div className="relative p-[16px_0] min-h-[auto] text-[rgba(0,0,0,.56)] flex text-[1rem] font-[500] justify-between leading-[2] whitespace-normal">
                <div className="flex items-start flex-1 min-w-0">
                    <p className='text-[rgba(0,0,0,.87)] leading-[2] text-[1rem] m-0 whitespace-pre-line'>برای استفاده از تمام امکانات دیوار دیل وارد حساب خود شوید.</p>
                </div>
            </div>
            <Link to="/login"> <button className="flex bg-[#fff] border-none box-border w-[100%] items-center text-[rgba(0,0,0,.56)] cursor-pointer h-[48px] outline-none p-[0_16px]">
            <svg className='ml-[8px] micon' width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13 12L21 12" stroke="#323232" strokeWidth="2" strokeLinejoin="round"/>
<path d="M16 15L13.087 12.087V12.087C13.039 12.039 13.039 11.961 13.087 11.913V11.913L16 9" stroke="#323232" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16 5V4.5V4.5C16 3.67157 15.3284 3 14.5 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H14.5C15.3284 21 16 20.3284 16 19.5V19.5V19" stroke="#323232" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
                <p className="text-[0.85rem] font-[600] text-inherit leading-[2] m-0 overflow-hidden text-ellipsis whitespace-nowrap">
                    ورود به حساب کاربری
                </p>
            </button>
            </Link>
        </div>
            }
            {userExist && <div>
                <div className='p-[8px_16px_8px_10px]'>
                    <div className="flex items-center text-[rgba(0,0,0,.56)]">
                    <svg className="text-[1.5rem] ml-[8px]" width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="12" cy="6" r="4" fill="#505050"/>
<ellipse cx="12" cy="17" rx="7" ry="4" fill="#505050"/>
</svg>
                        <div className='text-[0.935rem] font-[600] m-[0_8px_0_0] leading-[2] overflow-hidden text-ellipsis whitespace-nowrap'>کاربر دیوار</div>
                    </div>
                </div>
                <div className="m-0 text-[rgba(0,0,0,.5)] text-[0.85rem] font-[600] pr-[28px] leading-[2] overflow-hidden text-ellipsis whitespace-nowrap">
                    تلفن {localStorage.getItem("phone")}
                </div>
                <hr className='divider mt-2' />
                <Link to="/my/posts" className='items-center text-[rgba(0,0,0,.56)] cursor-pointer flex h-[48px] outline-none p-[0_16px] w-[100%]'>
                <svg className='ml-[8px] micon' width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21 7V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V7C3 4 4.5 2 8 2H16C19.5 2 21 4 21 7Z" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M15.5 2V9.85999C15.5 10.3 14.98 10.52 14.66 10.23L12.34 8.09003C12.15 7.91003 11.85 7.91003 11.66 8.09003L9.34003 10.23C9.02003 10.52 8.5 10.3 8.5 9.85999V2H15.5Z" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.25 14H17.5" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9 18H17.5" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
                <p className='m-0 text-inherit font-[600] text-[0.95rem] leading-[2] overflow-hidden text-ellipsis whitespace-nowrap'>آگهی های من</p>
                </Link>
                <hr className='divider mt-2' />
                <button onClick={() => {
                    localStorage.clear()
                    document.cookie = "phone=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                return navigate("/");
                }} className='bg-[#fff] border-none box-border w-[100%] items-center text-[rgba(0,0,0,.56)] cursor-pointer flex h-[48px] outline-none p-[0_16px]' type='button'>
                <svg className='ml-[8px] micon' width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 16.5V19C15 20.1046 14.1046 21 13 21H6C4.89543 21 4 20.1046 4 19V5C4 3.89543 4.89543 3 6 3H13C14.1046 3 15 3.89543 15 5V8.0625M11 12H21M21 12L18.5 9.5M21 12L18.5 14.5" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              <p className='text-inherit font-[600] text-[0.935rem] lading-[2] m-0 overflow-hidden text-ellipsis whitespace-nowrap'>
                  خروج از حساب
              </p>    
                </button>
            </div>}
    </div>
        <Footer />
    </div>
    <Nav />
    </>
  )
}

export default My