import Home from "./components/Home/Home";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Nav from "./components/Nav/Nav";
import Header from "./components/Header/Header";
import Cat from "./components/Categories/Cat";
import SubCat from "./components/Categories/SubCat";
import Ads from "./components/Ads/Ads";
import Login from "./components/LogInUp/Login";
import My from "./components/Home/User/My";
import Posts from "./components/Home/User/Posts";
import Add from "./components/Home/Add";
import AddPost from "./components/Home/AddPost2";
import Search from "./components/Home/Search";
import Chats from "./components/Home/Chat/Chats";
import Chat from "./components/Home/Chat/Chat";
import GetPostchi from "./components/Home/Chat/GetPostchi";
function App() {
  return (
    <Router>
      {/* <Home /> */}
      <Routes>
        <Route exact path="/" element={<Home />}></Route>

        <Route exact path="/cat" element={<Cat />}></Route>

        <Route exact path="/add" element={<Add />}></Route>
        <Route exact path="/add/:post" element={<AddPost />}></Route>

        <Route exact path="cat/:id" element={<SubCat />}></Route>

        <Route exact path="v/:id" element={<Ads />}></Route>

        <Route path="/chat" element={<Chats />}></Route>
        <Route path="/chat/:id" element={<Chat />}></Route>

        <Route path="/chat/postchi" element={<GetPostchi />}></Route>

        <Route path="/my" element={<My />}></Route>

        <Route path="/my/posts" element={<Posts />}></Route>

        <Route path="/s/:query" element={<Search />}></Route>

        <Route path="/login" element={<Login />}></Route>

      </Routes>
    </Router>
  );
}

export default App;
