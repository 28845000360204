import React, { useEffect, useState } from 'react'
import Header from '../../Header/Header'
import Nav from '../../Nav/Nav'
import { Link } from 'react-router-dom'
import Postchi from './Postchi'
const Chats = () => {
    const [ data, setData ] = useState(null)
    const token = localStorage.getItem("token") || null
    const getDate = (date) => {
    let correctDate;
    let nowDate = Date.now() / 1000
    let exactDate = Math.ceil((nowDate - date) / 60)
    let unit = 'لحظاتی '
    if (exactDate > 4) {
    // exactDate = 0
    unit = 'دقیقه'
    }


    if (exactDate > 1440) {
    exactDate = Math.round(exactDate / 1440)
    unit = 'روز'
    }


    if (exactDate > 60) {
    exactDate = Math.round(exactDate / 60)
    unit = 'ساعت'
}

correctDate = (unit === 'لحظاتی ') ? (unit  + " پیش") : exactDate + " " + unit  + " پیش"
return correctDate;
    }

useEffect(()=> {
    const sendReq = async () =>{
        const req = await fetch("https://api.divardi.li/chat.php",{
            method: "POST",
            body: JSON.stringify({
                token: token || null
            }),
            headers: {
                Authorization : token
            }
        })
        const data = await req.json()
        setData(data)
    }
    sendReq()
},[])


  return (
    <>
        <Header />
        <div className="relative h-[100%] block">
            <div className='px-[16px] w-[100%]'>
                <div className="relative flex w-[100%] h-[100%]">
                    <div className="w-[100%] overflow-hidden">
                    {/* <div className="relative item flex bg-[hsla(0,0%,100%,.98)] shadow-[0_1px_2px_rgba(0,0,0,.12)] h-[64px] justify-between pl-[16px] z-50">

                    </div> */}
                        <div className="h-[97%]">
                        <div className="flex relative h-[100%] w-[100%] will-change-scroll">
                        <div className="flex flex-col min-w-[100%] h-[100%] items-center justify-center">
                            <div className='container mb-[35px]'>
                              {/* postchi start */}
                                <Postchi />
                              {/* postchi end */}

                              {/* <Link ><div className='pt-[16px] bg-[rgba(240,250,255,.24)] cursor-pointer block'>
                                    <div className="flex">
                                        <div className='flex-1 w-[70%]'>
                                        <div className="">
                                        <p className="text-[0.935rem] leading-[2] font-[500] overflow-hidden text-ellipsis whitespace-nowrap m-0 text-[rgba(0,0,0,.87)] ">
                                                      محمد محمودی
                                                </p>
                                                <div className="flex items-center mt-[5px]">
                                             <p className='leading-[2] text-[0.85rem] font-[500] overflow-hidden text-ellipsis whitespace-nowrap text-[rgba(0,0,0,.87)] flex-1 m-0'> سلام چرا تخفیف نمیدی اخه؟ اگه دبی درجا میخرم </p>  
                                                </div>
                                            </div>
                                        <div className="flex items-center m-[12px_0_8px]">
                                            <div className="relative ml-[8px]">
                                            <div className="w-[48px] h-[48px]">
                                                    <picture className="mb-[100%] h-[100%] rounded-[5px] block overflow-hidden relative w-[100%]">
                                                      <img className='block w-[100%] h-[100%] left-0 top-0 object-cover absolute' src="http://192.168.1.109/php/dil/static/661233.webp" alt="" />  
                                                    </picture>
                                                </div>
                                            </div>
                                            <p className='leading-[2] text-[0.85rem] font-[500] overflow-hidden text-ellipsis whitespace-nowrap text-[rgba(0,0,0,.87)] flex-1 m-0'>
                                                ناردونه ترش ترش به شرط کیلو 120
                                            </p>
                                        </div>
                                        </div>
                                        <div className="flex flex-col-reverse  justify-between p-[2px_0_19px]">
                                        <div className="flex items-center justify-end">
                                        <p className="leading-[2] font-[600] text-[0.93rem] text-[rgba(0,0,0,.32)] m-[0_4px_0_0] text-left">
                                        {getDate(1693224666)}
                                        </p>
                                        </div>
                                        </div>
                                    </div>
                                    <hr className='divider mt-1' />
                                </div></Link> */}


                                {data?.map((item, index) => {
                                  return  <Link to={"/chat/" + item.adid + `?id=${item.chatid}`} key={index}><div className='pt-[16px] bg-[rgba(240,250,255,.24)] cursor-pointer block'>
                                    <div className="flex">
                                        <div className='flex-1 w-[70%]'>
                                        <div className="">
                                        <p className="text-[0.935rem] leading-[2] font-[500] overflow-hidden text-ellipsis whitespace-nowrap m-0 text-[rgba(0,0,0,.87)] ">
                                                      {item.sender === token ? item.receiver_name : item.sender_name}
                                                </p>
                                                <div className="flex items-center mt-[5px]">
                                             <p className='leading-[2] text-[0.85rem] font-[500] overflow-hidden text-ellipsis whitespace-nowrap text-[rgba(0,0,0,.87)] flex-1 m-0'> {item.content.text} </p>  
                                                </div>
                                            </div>
                                        <div className="flex items-center m-[12px_0_8px]">
                                            <div className="relative ml-[8px]">
                                            <div className="w-[48px] h-[48px]">
                                                    <picture className="mb-[100%] h-[100%] rounded-[5px] block overflow-hidden relative w-[100%]">
                                                      <img className='block w-[100%] h-[100%] left-0 top-0 object-cover absolute' src={item.images ? `https://static.divardi.li/images/${item.images}` : `https://static.divardi.li/no-pic.svg`} alt={item.title} />  
                                                    </picture>
                                                </div>
                                            </div>
                                            <p className='leading-[2] text-[0.85rem] font-[500] overflow-hidden text-ellipsis whitespace-nowrap text-[rgba(0,0,0,.87)] flex-1 m-0'>
                                               {item.title}
                                            </p>
                                        </div>
                                        </div>
                                        <div className="flex flex-col-reverse  justify-between p-[2px_0_19px]">
                                        <div className="flex items-center justify-end">
                                        <p className="leading-[2] font-[600] text-[0.93rem] text-[rgba(0,0,0,.32)] m-[0_4px_0_0] text-left">
                                        {getDate(item.content.date)}
                                        </p>
                                        </div>
                                        </div>
                                    </div>
                                    <hr className='divider mt-1' />
                                </div></Link>
                                })}

                            </div>
                        </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Nav />
    </>
  )
}

export default Chats